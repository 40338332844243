import React, { Component } from 'react';
import {Container, Table, Menu, Input, Button, Divider, Dropdown, Segment, Grid} from 'semantic-ui-react'; // eslint-disable-line no-unused-vars
import { BrowserRouter as Router, Route, Link } from "react-router-dom"; // eslint-disable-line no-unused-vars
import OpenSeadragon from 'openseadragon';
import {scalebar_enable} from './openseadragon-scalebar';

const queryString = require('query-string');


var seadragon_div_style = {
    width: '100%',
    height: '100vh',
    'backgroundColor': '#1a0933'
};

export default class Singleviewer extends Component {
    constructor(props) {
        super(props);

        scalebar_enable(OpenSeadragon);

        const parsed_url = queryString.parse(location.search); // eslint-disable-line no-restricted-globals
        try {
            var inst = parsed_url.inst.toString();
            var cancer = parsed_url.cancer.toString();
            var label = parsed_url.label.toString()
            var patient_num = parsed_url.patient_num.toString();
            var overlay = parsed_url.overlay.toString();
        } catch (e) {
            this.state = {
                globalViewer: undefined,
                error: true
            };
            return;
        }

        console.dir(parsed_url);

        this.state = {
            globalViewer: undefined,
            inst:inst,
            cancer: cancer,
            label: label,
            patient_num: patient_num,
            overlay: overlay,
            error: false
        };

        // This binding is necessary to make `this` work in the callback
        this.zoom_handler = this.zoom_handler.bind(this);
    }

    
    zoom_handler() {
        if ((this.state.globalViewer.world == undefined) ||
            (this.state.globalViewer.viewport == undefined) ||
            (this.state.globalViewer.world.getItemAt(0) == undefined) ||
            (this.state.globalViewer.world.getItemAt(0).getFullyLoaded() == false)) {
            // not loaded yet
            setTimeout(this.zoom_handler, 100);
        } else {
            var bounds = this.state.globalViewer.viewport.getBounds(true);
            var currentRect = this.state.globalViewer.viewport.viewportToImageRectangle(bounds);

            // correct for different sizes of iframe/window here
            var desired_center_point_x = (0.5 * (this.state.x2 + this.state.x1));
            var desired_center_point_y = (0.5 * (this.state.y2 + this.state.y1));
            var desired_width = this.state.x2 - this.state.x1;
            var desired_height = this.state.y2 - this.state.y1;

            var window_size = this.state.globalViewer.viewport.getContainerSize;

            var top_left_x_pixel = desired_center_point_x - (desired_width / 2);
            var top_left_y_pixel = desired_center_point_y - (desired_height / 2);
            var bot_right_x_pixel = desired_center_point_x + (desired_width / 2);
            var bot_right_y_pixel = desired_center_point_y + (desired_width / 2);

            var width = (bot_right_x_pixel - top_left_x_pixel)
            var height = (bot_right_y_pixel - top_left_y_pixel);

            var rect = new OpenSeadragon.Rect(parseFloat(top_left_x_pixel) / this.state.globalViewer.world.getItemAt(0).getContentSize()['x'] , parseFloat(top_left_y_pixel) / this.state.globalViewer.world.getItemAt(0).getContentSize()['y'], width / this.state.globalViewer.world.getItemAt(0).getContentSize()['x'], height / this.state.globalViewer.world.getItemAt(0).getContentSize()['y']);
            this.state.globalViewer.viewport.fitBounds(rect, true);
        }
    }

    componentDidMount() {
        var pixelsPerMeter = 2000000;

        if (this.state.error) {
            return;
        }

        var viewer = OpenSeadragon({
            id: "openseadragon1",
            prefixUrl: "/images/",
            showNavigator:  true,
            maxZoomLevel: 25,
            loadTilesWithAjax:true,
            ajaxHeaders : {},
            tileSources: [
                            'https://fastglioma-dziserver.s3.amazonaws.com/' + this.state.inst + '_' + this.state.cancer + '_' + this.state.label + '_' + this.state.patient_num + '_' + this.state.overlay + '.dzi'
                        ],
            sequenceMode: true,
            showReferenceStrip: true,
            gestureSettingsMouse: {clickToZoom: false},
            gestureSettingsTouch: {clickToZoom: false}
        });

        viewer.scalebar({
            type: OpenSeadragon.ScalebarType.MICROSCOPY,
            pixelsPerMeter: pixelsPerMeter,
            minWidth: "75px",
            location: OpenSeadragon.ScalebarLocation.TOP_LEFT,
            xOffset: 10,
            yOffset: 50,
            stayInsideImage: true,
            color: "rgb(100, 100, 100)",
            fontColor: "rgb(40, 40, 40)",
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            fontSize: "small",
            barThickness: 2
        });

        this.setState({globalViewer: viewer});
        if ((this.state.x1 != undefined) && (this.state.y1 != undefined) && (this.state.x2 != undefined) && (this.state.y2 != undefined)) {
            setTimeout(this.zoom_handler, 100);
        }

    }

    render() {
        if (this.state.error) {
            return (<div>URL Parsing Error</div>);
        } else {
            return (
                <div style={{fontFamily:'Helvetica'}}>
                    <center>
                        <div id="openseadragon1" style={seadragon_div_style}></div>
                    </center>
                </div>
            );
        }
    }
}
