import React, { Component } from 'react';
import {Container, Table, Menu, Input, Button, Divider, Dropdown, Segment, Grid} from 'semantic-ui-react'; // eslint-disable-line no-unused-vars
import { BrowserRouter as Router, Route, Link } from "react-router-dom"; // eslint-disable-line no-unused-vars
import OpenSeadragon from 'openseadragon';
import './openseadragon-curtain-sync';

const queryString = require('query-string');


var seadragon_div_style = {
    width: '100%',
    height: '100vh',
    'backgroundColor': '#1a0933'
};

export default class Curtainviewer extends Component {
    constructor(props) {
        super(props);
        const parsed_url = queryString.parse(location.search); // eslint-disable-line no-restricted-globals
        try {
            var i1_inst = parsed_url.i1_inst.toString();
            var i1_cancer = parsed_url.i1_cancer.toString();
            var i1_label = parsed_url.i1_label.toString();
            var i1_patient_num = parsed_url.i1_patient_num.toString();
            var i1_overlay = parsed_url.i1_overlay.toString();

            var i2_inst = parsed_url.i1_inst.toString();
            var i2_cancer = parsed_url.i2_cancer.toString();
            var i2_label = parsed_url.i1_label.toString();
            var i2_patient_num = parsed_url.i2_patient_num.toString();
            var i2_overlay = parsed_url.i2_overlay.toString();
        } catch (e) {
            this.state = {
                globalViewer: undefined,
                error: true
            };
            return;
        }

        this.state = {
            globalViewer: undefined,
            i1_inst: i1_inst,
            i1_cancer: i1_cancer,
            i1_label:i1_label,
            i1_patient_num: i1_patient_num,
            i1_overlay: i1_overlay,
            i2_inst: i2_inst,
            i2_cancer: i2_cancer,
            i2_label: i2_label,
            i2_patient_num: i2_patient_num,
            i2_overlay: i2_overlay,
            error: false
        };
    }

    componentDidMount() {
        var viewer = new window.CurtainSyncViewer({
            container: document.querySelector('#openseadragon1'),
            mode: 'curtain',
            // showNavigator:  true,
            images: [
                {
                  key: 'key',
                  tileSource: 'https://fastglioma-dziserver.s3.amazonaws.com/' + this.state.i1_inst + '_' + this.state.i1_cancer + '_' + this.state.i1_label + '_' + this.state.i1_patient_num + '_' + this.state.i1_overlay + '.dzi',
                  shown: true
                },
                {
                  key: '2',
                  tileSource: 'https://fastglioma-dziserver.s3.amazonaws.com/' + this.state.i2_inst + '_' + this.state.i2_cancer + '_' + this.state.i2_label + '_' + this.state.i2_patient_num + '_' + this.state.i2_overlay + '.dzi',
                  shown: true
                }
            ],
            
            // OpenSeaDragon options
            osdOptions: {
                showNavigator:true,
                showNavigationControl: true,
                navigationControlAnchor: OpenSeadragon.ControlAnchor.TOP_LEFT,
                prefixUrl: "images/",
                navigatorMaintainSizeRatio: true,
            }
        });

        this.setState({globalViewer: viewer});
    }

    render() {
        if (this.state.error) {
            return (<div>URL Parsing Error</div>);
        } else {
            return (
                <div style={{fontFamily:'Helvetica'}}>
                    <center>
                        <div id="openseadragon1" style={seadragon_div_style}></div>
                    </center>
                </div>
            );
        }
    }
}
