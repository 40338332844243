import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Singleviewer from './Singleviewer';
import Syncviewer from './Syncviewer';
import Curtainviewer from './Curtainviewer';

//import 'semantic-ui-css/semantic.min.css'

const RouteSet = () => (
  <Router>
    <div>
      <Routes>
        <Route path="/single" element={<Singleviewer />} />
        <Route path="/sync" element={<Syncviewer />} />
        <Route path="/curtain" element={<Curtainviewer />} />
        <Route path="*" element={<Send_404 />} />
      </Routes>
    </div>
  </Router>
);

function Send_404() {
  return (
    <div>
      404 Page not found
    </div>
  );
}

export default RouteSet;
